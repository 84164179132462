import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Box, ListItemText, Tooltip } from '@mui/material';
// locales
import { useLocales } from '../../../locales';
//
import Iconify from '../../iconify';
import { StyledDotIcon, StyledIcon, StyledItem } from './styles';
// ----------------------------------------------------------------------
export default function NavItem({ item, depth, open, active, isExternalLink, ...other }) {
    const { translate } = useLocales();
    const { title, path, icon, info, children, disabled, caption, roles } = item;
    const subItem = depth !== 1;
    const renderContent = (_jsxs(StyledItem, { depth: depth, active: active, disabled: disabled, caption: !!caption, ...other, children: [subItem && (_jsx(StyledIcon, { children: _jsx(StyledDotIcon, { active: active && subItem }) })), _jsx(ListItemText, { primary: `${translate(title)}`, secondary: caption && (_jsx(Tooltip, { title: `${translate(caption)}`, placement: "top-start", children: _jsx("span", { children: `${translate(caption)}` }) })), primaryTypographyProps: {
                    noWrap: true,
                    component: 'span',
                    variant: active ? 'subtitle2' : 'body2',
                }, secondaryTypographyProps: {
                    noWrap: true,
                    variant: 'caption',
                } }), info && (_jsx(Box, { component: "span", sx: { lineHeight: 0 }, children: info })), !!children && (_jsx(Iconify, { width: 16, icon: open ? 'eva:arrow-ios-downward-fill' : 'eva:arrow-ios-forward-fill', sx: { ml: 1, flexShrink: 0 } }))] }));
    const renderItem = () => {
        // ExternalLink
        if (isExternalLink)
            return (_jsx(RouterLink, { style: { textDecoration: 'none' }, to: path, children: renderContent }));
        // Has child
        if (children) {
            return renderContent;
        }
        // Default
        return (_jsx(RouterLink, { style: { textDecoration: 'none' }, to: path, children: renderContent }));
    };
    return _jsxs(_Fragment, { children: [" ", renderItem(), " "] });
}
